import { Config } from "common/config";

const config: Config = {
  backend: {
    apiAddress: "https://api.safetycon.fi/api/v1/",
    hubAddress: "https://api.safetycon.fi/hub/"
  },
};

export default config;

